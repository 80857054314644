<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2">
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Student Housing Application</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text v-if="!loaded">Loading your information...</v-card-text>
          <v-card-text v-else-if="hasStudyAbroadApp">It looks like you have applied to study abroad for the {{ termLabel }} term. If you still plan on studying abroad, please choose the "Study abroad this fall" option on the housing form. If this changes and your plans to study abroad are cancelled, then please return to this page to update your housing.</v-card-text>
          <v-card-text v-else-if="!isRegistered">It looks like you are not yet registered for the {{ termLabel }} term. Please ensure that you are registered in Banner then return here. Note that it may take up to a few hours from when you complete your registration before the housing system is updated.</v-card-text>
          <v-card-text v-else-if="requests.length === 0">You are all set to submit your housing request for next year. Please click the button below to open the housing form. Upon submitting it, you will see your current status on this page.</v-card-text>
          <v-card-actions v-if="loaded && !hasActiveRequest && (isRegistered || hasStudyAbroadApp)">
            <wizard :current="currentHousing" :current-roommates="currentRoommates" :term="term" :sex="sex" :study-abroad="hasStudyAbroadApp" @saved="load"></wizard>
          </v-card-actions>
        </v-card>
        <template v-if="loaded && isRegistered">
          <request-block v-for="(request, index) in requests" :key="'request-' + index" :request="request" :current="currentHousing" @approved="approved(index)" @declined="(val) => declined(index, val)"></request-block>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
export default {
  components: {
    requestBlock: () => import('@/components/student/housing/requestBlock'),
    Wizard: () => import('@/components/student/housing/wizard')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const term = ref('')
    const spring = ref('')
    const termLabel = ref('')
    const loaded = ref(false)
    const isRegistered = ref(false)
    const currentHousing = ref({
      bldg: '',
      building: '',
      hall: '',
      room: '',
      capacity: 0,
      offCampus: {
        address: '',
        city: '',
        state: '',
        zip: ''
      }
    })
    const currentRoommates = ref([])
    const sex = ref('')
    const hasStudyAbroadApp = ref(false)
    const requests = ref([])
    const hasActiveRequest = computed(() => {
      for (const { status } of requests.value) {
        if (status === 'Proposed' || status === 'Pending') return true
      }
      return false
    })

    onMounted(async () => {
      // Look for a term that is active for housing
      const { data } = await root.$feathers.service('system/term').find({ query: { 'housing.start': { $lte: new Date() }, 'housing.end': { $gte: new Date() } } })
      if (data.length === 0) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Housing is not currently available for any terms. Please check again later or contact Student Development for assistance.' })
        root.$router.replace('/')
        return
      }
      term.value = data[0].term
      termLabel.value = data[0].label
      if (term.value.substring(4, 6) === '60') {
        spring.value = term.value.substring(0, 4) + '05'
      }
      load()
    })

    async function load () {
      if (term.value === '') return
      /**
       * Check to see if this student has a study abroad application for the selected term (whether or not it is completed or even submitted)
       * Note: The term in the study abroad application is text and not the term code
       */
      const programTerm = 'Fall ' + term.value.substring(0, 4)
      const { data: saData } = await root.$feathers.service('student/study-abroad').find({ query: { pidm: user.value.pidm, programTerm } })
      if (saData.length > 0) {
        hasStudyAbroadApp.value = true
      }
      /**
       * Load the student's information to make sure they are registered and get their current housing information
       */
      const { data } = await root.$feathers.service('student/detail').find({ query: { pidm: user.value.pidm } })
      if (data.length > 0) {
        const { terms } = data[0]
        for (let i = terms.length - 1; i >= 0; i--) {
          if (terms[i].term === term.value) {
            isRegistered.value = terms[i].registered
            break
          }
        }
        // Look for roommates in the room table
        const { data: roomData } = await root.$feathers.service('student/housing-room').find({ query: { data: { $elemMatch: { term: spring.value, 'students.pidm': user.value.pidm } } } })
        if (roomData.length > 0) {
          const { hallId, hallName, room, data: roomTerms } = roomData[0]
          currentHousing.value.hall = hallName
          currentHousing.value.room = room
          const { bldg, building } = await root.$feathers.service('student/housing-hall').get(hallId)
          if (bldg) currentHousing.value.bldg = bldg
          if (building) currentHousing.value.building = building
          for (const { term, capacity, students } of roomTerms) {
            if (term === spring.value) {
              currentHousing.value.capacity = capacity
              for (const roommate of students) {
                if (roommate.pidm !== user.value.pidm) {
                  const { data: roommateData } = await root.$feathers.service('directory/people').find({ query: { pidm: roommate.pidm } })
                  if (roommateData.length > 0) {
                    const obj = { value: roommateData[0]._id, text: roommate.name, avatar: roommateData[0].photo, status: 'student' }
                    currentRoommates.value.push(obj)
                  }
                }
              }
              break
            }
          }
        }
      }
      /**
       * Load the student from the directory to get their sex to determine which rooms will be available in the search and who can be chosen as a roommate
       */
      const { data: dirData } = await root.$feathers.service('directory/people').find({ query: { pidm: user.value.pidm } })
      if (dirData.length > 0) {
        sex.value = dirData[0].gender
      } else {
        console.log('Error looking up user in the directory; user not found')
      }
      /**
       * Load any requests that the student is part of.
       */
      const { data: requestData } = await root.$feathers.service('student/housing-request').find({ query: { term: term.value, 'roommates.pidm': user.value.pidm, $sort: { status: 1, createdAt: 1 } } })
      // Resort the requestData
      requests.value = requestData.sort((a, b) => {
        // Pending first
        if (a.status === 'Pending') {
          // a is Pending, so a goes first
          return -1
        } else if (b.status === 'Pending') {
          // b is Pending and a is not; so b goes first
          return 1
        } else if (a.status === 'Proposed') {
          // a is Proposed and b is not Pending; so a goes first
          return -1
        } else if (b.status === 'Proposed') {
          // b is Proposed but A is not Proposed or Pending; so b goes first
          return 1
        } else {
          // For all other entries, keep the sort order the same (a goes first)
          return -1
        }
      })
      loaded.value = true
    }

    async function approved (index) {
      if (requests.value.length > 1) {
        for (let i = 0; i < requests.value.length; i++) {
          if (i === index) continue
          if (requests.value[i].status === 'Proposed' || requests.value[i].status === 'Pending') {
            const temp = await root.$feathers.service('student/housing-request').patch(requests.value[i]._id, { status: 'Declined', reason: user.value.preferred + ' ' + user.value.last + ' agreed to a different housing proposal.' })
            requests.value.splice(i, 1, temp)
          }
        }
      }
    }

    async function declined (index, val) {
      requests.value.splice(index, 1, val)
    }

    return {
      user,
      term,
      termLabel,
      loaded,
      isRegistered,
      currentHousing,
      currentRoommates,
      sex,
      hasStudyAbroadApp,
      requests,
      hasActiveRequest,
      load,
      approved,
      declined
    }
  }
}
</script>
